import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { config } from 'utils/apiEndPoint';

// Function to log in a user and store the tokens
export async function logIn(payload) {
    try {
        const response = await axios.post(`${config?.authentication?.signIn}`, payload);

        if (response?.data?.access) {
            localStorage.setItem('accessToken', response?.data?.access);
            localStorage.setItem('refresh-token', response?.data?.refresh);
        }

        return response;
    } catch (error) {
        console.error('Error in logIn API:', error);
        throw error;
    }
}

// Function to log in using phone number
export async function phonelogInService(payload) {
    try {
        const response = await axios.post(`${config?.authentication?.phonesign}`, payload);

        if (response?.data?.access) {
            localStorage.setItem('accessToken', response?.data?.access);
            localStorage.setItem('refresh-token', response?.data?.refresh);
        }

        return response;
    } catch (error) {
        console.error('Error in phonelogInService API:', error);
        throw error;
    }
}

// Function to create a new user and store the tokens
export async function createNewUser(payload) {
    try {
        const response = await axios.post(`${config?.authentication?.signUp}`, payload);

        if (response?.data?.access) {
            localStorage.setItem('accessToken', response?.data?.access);
            localStorage.setItem('refresh-token', response?.data?.refresh);
        }

        return response;
    } catch (error) {
        console.error('Error in createNewUser API:', error);
        throw error;
    }
}

// Function to get the current logged-in user by decoding the access token
export function getCurrentUser() {
    try {
        const token = localStorage.getItem('accessToken');
        return token ? jwtDecode(token) : null;
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
}

// Function to check if the access token is expired
export function isTokenExpired(token) {
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.exp < Date.now() / 1000 + 60; // Expiry check with a 1-minute buffer
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
}

// Function to handle token expiration, logout, and redirect to sign-in page
export const handleTokenExpiration = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refresh-token');
    if (window.location.pathname !== '/sign-in') {
        window.location.href = '/sign-in';
    }
};

// Function to refresh the access token using the refresh token
export const refreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh-token');
        if (!refreshToken) throw new Error("No refresh token available");

        const response = await axios.post(config?.authentication?.refreshToken, { refresh: refreshToken });

        if (response?.data?.access) {
            localStorage.setItem('accessToken', response?.data?.access);
        }
        return response?.data?.access;
    } catch (error) {
        console.error("Token refresh failed:", error);
        return null;
    }
};

// Check if the refresh token is expired or invalid
export const isRefreshTokenExpired = () => {
    try {
        const refreshToken = localStorage.getItem('refresh-token');
        if (!refreshToken) return true;

        const decodedToken = jwtDecode(refreshToken);
        return decodedToken.exp < Date.now() / 1000;
    } catch (error) {
        console.error("Error decoding refresh token:", error);
        return true;
    }
};

// Function to handle expired refresh token
export const handleExpiredRefreshToken = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refresh-token');
    if (window.location.pathname !== '/sign-in') {
        window.location.href = '/sign-in';
    }
};
